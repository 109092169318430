<template>
  <div class="main">
    <!-- 发货记录  -->
    <!-- 顶部标题 -->
    <div class="header">
      <div class="header-title">
        <b class="title">发货记录查询</b
        >
        <!-- <span style="color: #555555; font-size: 12px; margin-left: 8px"
          >(最近90天)</span> -->
      </div>
    </div>
    <!-- 头部查询 -->
    <div class="select">
      <el-form inline :model="formData" label-min-width="50px" :rules='rules' ref='ruleForm'>
        <el-form-item
          style="display: block"
          label="发货时间："
          prop="startTime"
        > 
          <el-date-picker
            v-model="formData.startTime"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            size="mini"
            style="width: 310px"
            :default-time="['00:00:00', '23:59:59']"
          >
          </el-date-picker>
        </el-form-item>
        <div class="select-msg">
          查询条件：
        </div>
        <div style="display: inline-block; position:relative;left:12px;">
          <el-form-item>
            <el-select
              v-model="formData.expressList"
              placeholder="所有快递"
              size="mini"
              style="width: 110px;margin-right:10px;"
            >
              <el-option
                v-for="item in selectType"
                :key="item.id"
                :label="item.name"
                :value="item.company_no"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="收件人" class="header-txt">
            <el-input v-model="formData.name"></el-input>
          </el-form-item>
          <el-form-item label="快递单号" class="header-txt">
            <el-input v-model="formData.orderNum"></el-input>
          </el-form-item>
          <el-form-item label="订单号" class="header-txt">
            <el-input v-model="formData.bianhao"></el-input>
          </el-form-item>
        </div>
      </el-form>
      <div class="select-btn">
        <el-button type="primary" size="mini" @click="handleSelect" style="margin-right:30px;"
          >查询</el-button
        >
        <!-- <el-button type="primary" size="mini" @click="handleDownLoads"
          >下载</el-button
        > -->
      </div>
    </div>
    <!-- 表格数据 -->
    <div class="table-data" v-if='tableShow'>
      <div class="table-top">
        共<span class="color-red">{{ distributor_num_show }}</span
        >买家/<span class="color-red">{{ order_num_show }}</span
        >订单/<span class="color-red">{{ totalNum }}</span
        >条记录
        <!-- <span style="color:rgb(114, 114, 114); padding: 0px 2px">(默认只能下载1万单)</span> -->
        
      </div>
      <el-table
        ref="multipleTable"
        :data="deliverGoodsData"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column width='70' prop='id'></el-table-column>
        <el-table-column prop="nickname" label="收件人"></el-table-column>
        <el-table-column prop="shop_order_id" label="订单号"> </el-table-column>
        <el-table-column prop="express_name" label="快递名称"> </el-table-column>
        <el-table-column prop="express_number" label="快递单号"> </el-table-column>
        <el-table-column prop="create_time" label="发货时间">
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
      <div v-if='tableShow' style="float: right;padding:10px 5px;">
          <p style="display: inline-block;transform: translateY(4px);">每页显示</p>
          <el-pagination
            background
            layout="sizes,prev, pager, next"
            :total="totalNum"
            style="display: inline-block"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
            :current-page='currentPage'
            :page-size='pageNum'
            :page-sizes="[50,100, 200]"
            v-if='pageShow'
          >
          </el-pagination>
        </div>
  </div>
</template>
<script>
import {mapState,mapActions} from 'vuex'
export default {
  computed:{
    ...mapState({
      deliverGoodsData: (state) => state.utilities.delivergoods_list,
      selectType:(state) => state.print.expressList,
    }),
  },
  data() {
    return {
      pickerOptions: {
        disabledDate:(date)=>{ // 限制查询3个月内数据
            let moment = this.moment
            if(
                moment(date).isBefore(moment().subtract(2, 'M').startOf("M"))
                || moment(date).isAfter(moment().endOf("D"))
            ){
                return true
            }
        }
      },
      rules:{
        startTime:[
          { required: true, message: '请选择日期', trigger: 'blur' },
        ]
      },
      formData: {
        startTime: [],
        expressList: "所有快递",
        name: "",
        dayin: "",
        orderNum: "",
        bianhao: "",
        BICNum: "",
      },
    params:{},
    currentPage:1,
     offset:1,//页码
     pageNum:50,//每页显示几条
     totalNum: 0,//订单记录数
    order_num_show:0,//订单数
    distributor_num_show:0,//买家数
     tableShow:false,
     selectTypeList:[],
     pageShow:true
    };
  },
  methods: {
    ...mapActions('utilities',['getDeliveryRecord']),
    handleSelect() {
      this.currentPage = 1
      this.offset = 1
      this.pageShow = false
     setTimeout(()=> {
        this.pageShow = true
      },0)
      this.$refs.ruleForm.validate((valid) => {
          if(!valid) return false
          this.params={
              express_code:this.formData.expressList=='所有快递'?'':this.formData.expressList,
              start_time: this.formData.startTime[0],
              end_time: this.formData.startTime[1],
              transaction_id: this.formData.bianhao,
              express_number: this.formData.orderNum,
              nickname: this.formData.name,
          }
          this.getList()
            })
      
    },
    getList() {
      this.tableShow=true;
      let params = {
        limit: this.pageNum,
        pageNum:this.offset,
        start_time: this.getBeginTime(),
        end_time: this.getEndTime(),
      };
      params = { ...params, ...this.params };
      this.getDeliveryRecord(params)
        .then((res) => {
          // console.log(res)
          // this.totalNum=res.data.total
          this.totalNum=res.data==false?'0':res.data.total  // 记录数
          this.order_num_show=res.data==false?'0':res.data.order_num  // 订单数
          this.distributor_num_show=res.data==false?'0':res.data.distributor_num  // 买家数
        })
        .catch((error) => {});
    },
    // handleDownLoads(){
    //   console.log('点击了下载按钮');
    // },
     handleCurrentChange(page){
      this.offset=page
      this.getList()
    },
    handleSizeChange(val){
      this.offset=1
      this.pageNum=val
      this.getList()
      this.pageShow = false
      setTimeout(()=> {
        this.pageShow = true
      },0)
    },
     getBeginTime() {
      let now=new Date().getTime();
      let days = 34;
      let agoTimes = now-86400*1000*days;
      let time = new Date(agoTimes);
      let year = time.getFullYear();
      let mounth = time.getMonth() + 1;
      let date = time.getDate();
      mounth = mounth < 10 ? "0" + mounth : mounth;
      date = date < 10 ? "0" + date : date;
      return year + "-" + mounth + "-" + date + " 00:00:00";
    },
    getEndTime() {
      var time = new Date();
      var year = time.getFullYear();
      var mounth = time.getMonth() + 1;
      var date = time.getDate();
      mounth = mounth < 10 ? "0" + mounth : mounth;
      date = date < 10 ? "0" + date : date;
      return year + "-" + mounth + "-" + date + " 23:59:59";
    },
  },
    mounted() {
    this.$store.dispatch('print/get_express_list').then(()=> {
      this.selectTypeList=this.selectType
      this.selectTypeList.unshift({id:'10',name:'全部快递',company_no:''})
      this.$store.commit("print/SET_EXPRESS_LIST", this.selectTypeList);
    })
    },
  created(){
    this.formData.startTime.push(this.getBeginTime())
    this.formData.startTime.push(this.getEndTime())
  }
};
</script>

<style  scoped>
  .main {
    padding: 0 10px;
  }
  .color-red {
    color: red;
    padding: 0px 2px;
  }
  .header-title {
    height: 55px;
    line-height: 55px;
    font-size: 20px;
    color: rgb(56, 162, 219);
    border-bottom: 1px dotted rgb(85, 85, 85);
  }
  .select,.table-data {
    padding-left: 24px
}
  .select-msg {
    display: inline-block;
      color: black;
      font-weight: 600;
      font-size: 14px;
      position: relative;
      top: 8px;
  }
  * >>> .el-form-item__label {
    font-weight: 600;
    color: black;
  }
  .header-txt >>> .el-form-item__label {
    font-weight: 600;
    color: #49689a;
  }
  .el-form-item {
    margin-bottom: 0px;
  }
  .header-txt >>> .el-input__inner {
    width: 120px;
    height: 22px;
  }
  .pici >>> .el-input__inner {
    width: 200px;
  }
  .btn-white {
    color: black;
    border: 1px solid #b3abab;
    background: linear-gradient(#fffcf7, #edeaea);
  }
  .select-btn {
    padding: 15px 84px;
  }

  .table-top {
    height: 40px;
    line-height: 30px;
    border-bottom: 3px solid rgb(56, 162, 219);
  }
* >>> .el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before {
  content: '';
  margin-right: 0px;
}
* >>> .el-table .cell {
  text-align: center;
}
</style>